<template>
  <article
    class="md:prose-md lg:prose-md prose dark:prose-invert sm:prose-sm xl:prose-lg 2xl:prose-xl"
  >
    <h1 class="mb-4 text-4xl font-bold">&lt;(￣︶￣)&gt;</h1>
    <p class="mb-2 line-clamp-3">
      Hey there! This is a nifty little project that's all about converting
      images in JPG, PNG, WEBP, and SVG formats. But that's not all! It also
      optimizes your images and resizes them. Pretty cool, huh?
    </p>
    <p class="mb-2">
      We've got a split front and server setup here, with all the chit-chat
      happening via API. You can find all the juicy details on GitHub. This
      project is built with VUE and Fastify, and here's the kicker - we don't
      keep your pics on our server. We process them in a buffer and send them
      straight back to you. We don't store your data, and we're as cuddly as a
      kitten. How's that for privacy?
    </p>
  </article>
</template>
